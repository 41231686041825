@tailwind base;
@tailwind components;
@tailwind utilities;
/* @import url("https://fonts.googleapis.com/css2?family=Noto+Sans:wght@300;400;500;600;700;800&display=swap"); */

/* @font-face {
  font-family: "Noto Sans", sans-serif;
  src: local("ArialBold"), url("./fonts/ARIALBD.TTF") format("truetype");
  font-weight: bold;
}

@font-face {
  font-family: "Noto Sans", sans-serif;
  src: local("ArialReg"), url("./fonts/ArialCE.ttf") format("truetype");
  font-weight: normal;
} */

@font-face {
  font-family: "Noto Sans", sans-serif;
  src: url(fonts/ARIALBD.TTF);
}
@font-face {
  font-family: "Noto Sans", sans-serif;
  src: url(fonts/ArialCE.ttf);
}
.detailmove{
  margin-top: 4rem !important;
}
.emailadmin{
  position: relative !important;
}
.errormsg{
  position: absolute;
}
body {
  font-family: "Noto Sans", sans-serif !important;
}
* {
  font-family: "Noto Sans", sans-serif !important;
}
.downward {
  color: #cc0605;
}
.iconwidth {
  width: 25px;
}
.upward {
  color: #06ab04;
}

.downward img,
.upward img {
  width: 15px;
}

/*  */

.progressbar{
	position: relative;
	overflow: hidden;
	width: 350px;
	height: 35px;
	border-radius: 5px;
	background-color: #eee;
}

.progressPercent{
	font-weight: 600;
	font-family:'Franklin Gothic Medium';
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%,-50%);
	color: #eee;
	text-shadow: -1px 0 #555, 0 1px #555, 1px 0 #555, 0 -1px #555;
}

/*  */

.new-added-users {
  color: #48a4da;
}

.rounded-icon {
  width: 40px !important;
  height: 40px !important;
  border-radius: 100%;
}

.para {
  color: #50504f;
  font-size: 0.8rem;
}

.primary-color {
  color: #0040a1 !important;
}



.btn2 {
  border: 2px solid #0040a1 !important;
  border-radius: 0% !important;
  padding: 0px !important;
  color: #0040a1 !important;
  font-weight: bold !important;
}
.newsfeed{
  cursor: pointer;
}

.btn2 .minus {
  background-color: #0040a1;
}

.btn2.medium {
  padding: 3px 10px !important;
}

.post-img {
  max-width: 70px;
  height: auto;
}

.hover-row {
  cursor: pointer;
}

.hover-row:hover .eye-icon {
  display: block;
}

.eye-icon {
  width: 30px;
  padding: 0 5px;
  display: none;
}

table .icon {
  width: 50px;
}

textarea {
  outline: none !important;
  border-bottom: 1px solid rgba(0, 0, 0, 0.42);
}

textarea:hover {
  border-bottom: 2px solid #000;
}

textarea:focus {
  border-bottom: 2px solid #0040a1;
}

.MuiInput-underline:before {
  content: "" !important;
}

.search-wrapper input {
  border: 0 !important;
  outline: none !important;
}

.primary-btn {
  border: none;
  background-color: #e27100;
  color: #ffffff;
  outline: none;
  width: auto;
  padding: 0.5rem 1.5rem;
  font-size: 0.9rem;
  font-weight: 700;
  text-transform: uppercase;
  border-radius: 0 !important;
}

.secondary-btn {
  border: none;
  background-color: #0040a1 !important;
  color: #fff !important;
  outline: none;
  width: auto;
  padding: 0.5rem 1.5rem !important;
  font-size: 0.9rem;
  font-weight: 700;
  text-transform: uppercase;
  border-radius: 0 !important;
}

button .minus {
  width: 8px;
  height: 2px;
  background-color: #fff;
  margin-right: 8px;
}

.auth-form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.auth-form input {
  width: 100%;
  border: none;
  padding: 0.5rem 1.5rem;
  background-color: #fff !important;
  margin-bottom: 2rem;
  outline: none;
}

.auth-form button {
  max-width: 150px;
}

.auth-container {
  background-color: #f6f6f6;
  padding: 4rem 0;
  position: relative;
  box-shadow: 2px 6px 20px #dededec7, 0 6px 6px #dededec7;
}

.auth-container::before {
  position: absolute;
  content: " ";
  width: 100%;
  height: 10px;
  background-color: #e27100;
  left: 0;
  bottom: 10px;
}

.auth-container::after {
  position: absolute;
  content: " ";
  width: 100%;
  height: 10px;
  background-color: #0040a1;
  left: 0;
  bottom: 0px;
}

.text-btn {
  color: #636363;
  font-size: 12px;
  margin-top: 1rem;
  cursor: pointer;
}

.text-btn2 {
  margin-left: 5px;
  display: inline-block;
  border-bottom: 1px solid #bec0c7;
  color: #bec0c7;
  line-height: 1;
  font-size: 0.8rem;
  cursor: pointer;
}

.button-1 {
  width: auto;
  min-width: 100px;
  border: 0;
  border-radius: 0;
  background-color: #e27100;
  color: #ffffff;
  font-size: 0.9rem;
  text-transform: uppercase;
  padding: 0.5rem;
  font-weight: 600;
}
.cropbutton {
  width: 200px !important;
  min-width: 100px;
  border: 0;
  border-radius: 0;
  background-color: #e27100;
  color: #ffffff;
  font-size: 0.9rem;
  text-transform: uppercase;
  padding: 0.5rem;
  font-weight: 600;
  margin-left: 10px;
}
.buttons-1 {
  width: auto;
  min-width: 100px;
  border: 0;
  border-radius: 0;
  background-color: #66625e;
  color: #ffffff;;
  font-size: 0.9rem;
  text-transform: uppercase;
  padding: 0.5rem;
  font-weight: 600;
}

.PrivateTabIndicator-colorSecondary-3 {
  background-color: #0040a1 !important;
}

.custom-tab-btn {
  border: 0 !important;
  color: #bec0c7 !important;
  font-weight: 700;
  cursor: pointer;
}

.custom-tab-btn.active {
  color: #0040a1 !important;
  font-weight: 700;
  letter-spacing: 0.5px;
  /* border-bottom: 3px solid #0040a1 !important; */
}

/* Layout */
.layout {
  display: flex;
}

.side-nav {
  width: 240px;
  height: 100vh;
  transition: width 0.3s ease;
}
.side-navs {
  width: 0px;
  height: 100vh;
  transition: width 0.3s ease;
}
.icon{
  display: none;
}
.page-container {
  width: calc(100% - 240px);
  height: 100vh;
  background-color: #f6f6f6;
}
.page-containers {
  width: 100%;
  height: 100vh;
  background-color: #f6f6f6;
}

.header {
  width: 100%;
  height: 70px;
  display: flex;
  padding: 20px 0;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  box-shadow: 5px 5px 25px #dededecc;
}

.navbar_active {
  color: #0040a1;
}

.notification_rounded {
  border-radius: 100%;
}

.header .search-wrapper {
  width: 100%;
  max-width: 500px;
  height: 100%;
}
.header .search-wrapper img{
  width: 45px;
  height: 45px;
}
.body-container {
  height: calc(100vh - 70px);
  padding-top: 48px;
  overflow-y: auto;
}

.inner-header {
  width: calc(100% - 240px) !important;
  position: fixed !important;
  top: 70px;
}


/* Layout */

/* SideNav */
#sideNav {
  background-color: #0040a1;
  height: 100%;
  padding: 0 2.5rem;
  color: #fff;
 overflow: auto;
}
#sideNav::-webkit-scrollbar {
  width: 8px;
}
#sideNav::-webkit-scrollbar-thumb {
  background: #cdd8ea;
  border-radius: 4px;
}
#sideNav::-webkit-scrollbar-thumb:hover {
  background: #555;
}

#sideNav .profile-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem 0;
  border-bottom: 2px solid #e27100;
}

#sideNav .profile-info .title {
  font-size: 1.5rem;
  margin-top: 0.5rem;
}

#sideNav .profile-info .sub-title {
  color: #e27100;
  font-size: 0.9rem;
  margin-top: 0.5rem;
}

#sideNav .navlist {
  height: calc(100vh - 28vh);
}

#sideNav .navlist ul {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding-top: 3rem;
  padding-bottom: 0rem;
}
#sideNav .navlist ul li {
padding: 10px 0px;
}
.AddSession {
  display: flex;
  justify-content: flex-end;
  margin: 20px 40px 0px 0px;
  margin-left: auto;
}
/* SideNav */

/* Custom-list */
.custom-list li {
  font-size: 0.8rem;
  color: #636363;
  border-top: 1px solid #f6f6f6;
  border-bottom: 1px solid #f6f6f6;
  padding: 0.3rem 0;
  margin-bottom: 0.5rem;
}

/* Custom-list */

/* Tables */
.primary-table-theme .head {
  background-color: #dee9f9 !important;
  color: #0040a1 !important;
  border: 1px solid #dee9f9 !important;
  font-size: 0.8rem;
  z-index: 9;
  text-align: left;
}

.table-theme-1 th {
  position: sticky;
  top: 0px;
  background-color: #dee9f9 !important;
  color: #0040a1 !important;
  border: 1px solid #dee9f9 !important;
  padding: 0.5rem;
  font-size: 0.8rem;
  z-index: 9;
  text-align: left;
}

.table-theme-1 tr {
  box-shadow: none;
}

.table-theme-1 tr:hover {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px !important;
}

.table-theme-1 td {
  position: relative;
  border: 0 !important;
  border-left: 0 !important;
  border-right: 0 !important;
  background-color: #fff !important;
  padding: 1rem 0.5rem;
  font-size: 0.8rem;
  text-align: left;
}

.table-theme-1 td::before {
  content: "";
  position: absolute;
  width: 100%;
  left: 0;
  top: 5px;
  height: 1px;
  background-color: #f6f6f6;
}

.table-theme-1 td::after {
  content: "";
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 5px;
  height: 1px;
  background-color: #f6f6f6;
}

.custom-table .head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #dee9f9;
  color: #0040a1;
  font-size: 0.8rem;
  z-index: 5;
}

.custom-table .head li {
  padding: 1rem 2rem;
}

#manage-tab .head li:nth-child(1) {
  width: 30%;
}

#manage-tab .head li:nth-child(2) {
  width: 15%;
}

#manage-tab .head li:nth-child(3) {
  width: 15%;
}

#manage-tab .head li:nth-child(4) {
  width: 15%;
}

#manage-tab .head li:nth-child(5) {
  width: 25%;
}

.custom-table .body {
  padding-left: 1rem;
  padding-right: 1rem;
}

.custom-table .custom-row {
  display: flex;
  padding-top: 3rem;
  padding-bottom: 2rem;
  height: 480px;
  border-bottom: 1px solid #f6f6f6;
}

.custom-table .body .left {
  width: 30%;
  padding: 1rem;
}

.custom-table .body .right {
  width: 70%;
  padding: 1rem 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.custom-table .body .comment-section {
  height: 70%;
  overflow-y: auto;
}

.post-detail .header,
.post-polling .header {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: absolute;
  top: -9%;
  background-color: transparent;
  box-shadow: none;
  padding: 0 0.6rem;
  color: #0040a1;
  font-size: 0.9rem;
  height: auto;
  z-index: 2;
}

.post-detail .header img,
.post-polling .header img {
  width: 50px;
  height: 50px;
  border-radius: 100%;
  margin-right: 10px;
  border: 2px solid #fff;
}

.post-detail .header img.home,
.post-polling .header img.home {
  width: 55px;
  height: 55px;
}

.post-detail.without-text .footer {
  padding-top: 50px;
}

.post-detail .footer .title {
  padding-top: 0.5rem;
  color: #0040a1;
}

.post-detail .footer .text {
  font-size: 0.8rem;
  color: #50504f;
}

.post-polling.modal-view,
.post-detail.modal-view {
  display: flex;
  justify-content: space-between;
}

.post-polling.modal-view .content,
.post-detail.modal-view .content {
  width: 50%;
}

.post-polling.modal-view .footer,
.post-detail.modal-view .footer {
  width: 45%;
}

.post-polling.modal-view .footer h3,
.post-detail.modal-view .footer h3 {
  margin-bottom: 10px !important;
  border: 0 !important;
}

.polling {
  font-size: 0.8rem;
  color: #50504f;
  padding: 0.5rem;
  background-color: #fff;
  margin-top: 1rem;
  cursor: pointer;
  transition: 0.5s all ease;
  position: relative;
}

.polling span {
  position: relative;
  z-index: 9;
  color: #0040a1;
  font-weight: 600;
}

.polling .progress-bar {
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #dee9f9;
}

.polling .progress-text {
  position: absolute;
  top: 0;
  right: 10px;
  height: 100%;
  display: flex;
  align-items: center;
  color: #0040a1;
  font-weight: 600;
}

.custom-table .comment-lists {
  display: flex;
  align-items: center;
  font-size: 0.8rem;
  color: #50504f;
  border-top: 0.9px solid #f6f6f6;
  border-bottom: 0.9px solid #f6f6f6;
  padding: 0.3rem 0;
  margin-bottom: 0.5rem;
}

.custom-table .comment-lists li {
  display: flex;
  align-items: center;
}

.custom-table .comment-lists li:nth-child(1) {
  width: 25%;
}

.custom-table .comment-lists li:nth-child(2) {
  width: 23%;
}

.custom-table .comment-lists li:nth-child(3) {
  width: 22%;
}

.custom-table .comment-lists li:nth-child(4) {
  width: 32%;
}

.custom-table .comment-lists li:nth-child(1) img {
  width: 40px;
  height: 40px;
  border-radius: 100%;
  margin-right: 10px;
}

/* Tables */

/* Modal */
#custom-modal {
  margin: 0 auto;
  width: 100%;
  border: 0;
  outline: none;
  padding: 2rem 3rem 5rem;
  max-height: 90vh;
  overflow-y: scroll;
}

#custom-modal .title {
  font-size: 1.2rem;
  color: #0040a1;
  font-weight: 600;
  width: auto;
  margin-bottom: 3rem;
  text-transform: uppercase;
  border-bottom: 2px solid #0040a1;
}

#custom-modal .label,
#custom-modal .radio-label span {
  font-size: 1rem;
  color: #0040a1;
  font-weight: 600;
  width: auto;
}

#custom-modal .radio-label {
  margin-right: 2rem;
}

#custom-modal .cross {
  position: relative;
  display: block;
  margin-left: auto;
  width: 20px;
  height: 20px;
  cursor: pointer;
  margin-bottom: 2rem;
  right: -3rem;
  top: -25px;
}

#custom-modal .cross::before {
  content: " ";
  width: 2px;
  height: 100%;
  background-color: #000;
  top: -2px;
  right: 99px;
  position: absolute;
  transform: rotate(134deg) translate(2.5px, -0.5px);
  right: 8px;
}

#custom-modal .cross::after {
  content: " ";
  width: 2px;
  height: 100%;
  background-color: #000;
  top: -7px;
  right: 19px;
  position: absolute;
  transform: rotate(225deg) translate(-9.5px, 0px);
  right: 16px;
}

#custom-modal .warning-modal .title {
  color: #cb0000;
  border-bottom: 2px solid #cb0000;
  text-align: center;
}

#custom-modal .warning-modal p {
  margin-bottom: 1rem;
  font-size: 0.9rem;
  text-align: center;
}

#custom-modal .warning-modal button {
  border-width: 1px !important;
  font-weight: 400 !important;
  padding: 0.3rem 2rem !important;
}

#custom-modal .warning-modal .approve_decline_padding {
  background-color: #cb0000;
  color: #fff !important;
  margin-left: 96px;
}

#custom-modal .warning-modal .approve_decline_padding .cross-icon::before,
#custom-modal .warning-modal .approve_decline_padding .cross-icon::after {
  background-color: #fff;
}
.innerbox {
  width: 100%;
}
.mainevent {
  display: flex;
  justify-content: flex-start;
}
.leftevent {
  display: flex;
  width: 25%;
}
.rightevent {
  display: flex;
  text-align: right;
  width: 75%;
  text-align: left;
}
.note {
  padding-top: 50px;
}
/* Modal */

/* Form Styling */
.custom-form-element {
  border: 0 !important;
  border-bottom: 1px solid #f6f6f6 !important;
  border-radius: 0 !important;
}

/* Form Styling */
.approve_decline_padding.danger-bg {
  background-color: #cb0000;
  color: #fff !important;
}
.approve_maintenace_padding.yellow-bg {
  background-color: yellow;
  color: #0040a1 !important;
  font-weight: bold;
}
.approve_maintenace_padding.yellow-bg:hover {
  background-color: yellow !important;
  color: #0040a1 !important;
}

.approve_decline_padding.danger-bg .cross-icon::before {
  background-color: #fff;
}

.approve_decline_padding.danger-bg .cross-icon::after {
  background-color: #fff;
}

.approve_decline_padding.danger-bg:hover {
  color: #cb0000 !important;
}

.approve_decline_padding.danger-bg:hover .cross-icon::before {
  background-color: #cb0000;
}

.approve_decline_padding.danger-bg:hover .cross-icon::after {
  background-color: #cb0000;
}

.approve_decline_padding .cross-icon {
  position: relative;
  width: 15px;
  height: 15px;
}

.approve_decline_padding .cross-icon::before {
  position: absolute;
  content: "";
  width: 2px;
  height: 15px;
  background-color: #cb0000;
  top: 0;
  left: 0;
  transform: rotate(45deg);
}

.approve_decline_padding .cross-icon::after {
  position: absolute;
  content: "";
  width: 2px;
  height: 15px;
  background-color: #cb0000;
  top: 0;
  left: 0;
  transform: rotate(315deg);
}

.approve_decline_padding.cross-icon {
  position: relative;
}

.MuiButton-outlinedSecondary {
  color: #cb0000 !important;
  border: 1px solid #cb0000 !important;
}

/* request page */
.approval_button_style.mr-10 {
  margin-right: 2rem;
}

.approve_decline_padding.ml-10 {
  margin-left: 2rem;
}

.approval_button_style {
  color: #06ab04 !important;
  border-color: #06ab04 !important;
  border-radius: 0 !important;
}

.approve_decline_padding {
  border-radius: 0 !important;
}

/* request page */

/* event page */
.text_tosmall_10 {
  font-size: 10px;
}

.react-calendar {
  width: 95%;
  background: none;
  line-height: 1.125em;
  box-shadow: 5px 5px 25px #dededecc;
  border: none;
}

.react-calendar__navigation {
  height: 44px;
  margin-bottom: 1em;
  display: block !important;
}

.react-calendar button {
  padding: 15px 0px;
  margin: 15px 0;
  color: #50504f;
}

abbr[title] {
  text-decoration: none;
  color: #0040a1;
}

.react-calendar__month-view__weekdays {
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 0.75em;
  background: #dee9f9;
}

.react-calendar__tile--now,
.react-calendar__tile--active {
  background: none;
}

.react-calendar__tile--now abbr {
  background: #0040a1;
  border-radius: 50%;
  color: #fff;
  font-weight: bold;
  border-radius: 50%;
  padding: 13px 20px;
}

.react-calendar__tile--active abbr {
  background: #e27100;
  color: #0040a1;
  font-weight: bold;
  border-radius: 50%;
  border-radius: 50%;
  padding: 13px 20px;
}
.cutome_calander_class abbr {
  position: relative;
  padding: 13px 17px;
}
.cutome_calander_class abbr::after {
  content: ".";
  position: absolute;
  bottom: 20%;
  left: 41%;
  color: #0040a1;
  font-weight: bold;
  font-size: 35px;
}

.react-calendar__navigation button span {
  color: #0040a1;
  font-weight: bold;
}

.react-calendar__navigation button {
  color: #bec0c7;
}

.react-calendar__month-view__days {
  background-color: white;
}

.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background: none;
}

.react-calendar__tile--now:enabled:hover {
  background: #e6e6e6;
}
.react-calendar__navigation__label {
  width: 10rem;
}

/* event page */

.row-border-bottom {
  width: 80%;
  margin: 2rem auto;
  background-color: #f6f6f6;
  height: 2px;
}

.removed-post.divider {
  position: relative;
}

.removed-post .avatar {
  width: 30px;
  height: 30px;
  border-radius: 100%;
  margin-right: 5px;
}

.removed-post.divider::before {
  content: "";
  position: absolute;
  left: -9%;
  top: 15%;
  display: block;
  width: 2px;
  height: 80%;
  background-color: #f6f6f6;
}

.media-box {
  display: flex;
  justify-content: center;
  color: #bec0c7;
  font-size: 1rem;
  box-shadow: 5px 5px 20px #d4d4d4b3;
  padding: 1rem;
  max-width: 90%;
  cursor: pointer;
}

.media-box img {
  margin-right: 5px;
  max-width: 100%;
}

.floating-box {
  position: absolute;
  right: 0;
  bottom: 0;
  background-color: #0040a1;
  color: #fff;
  height: 30px;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.bg-green {
  background-color: rgb(10, 180, 10);
}

.bg-orange {
  background-color: #ffaa00;
}

.radio-group {
  flex-direction: row !important;
  flex-wrap: nowrap !important;
}

.MuiFormControlLabel-root span {
  width: max-content;
}

.latest-post-card {
  height: 360px;
}

.comment-bg {
  background-color: #f6f6f6;
}

.a {
  fill: #bec0c7;
  transition: all 0.2 ease;
}

.a:hover {
  fill: #0040a1;
}

.comment-section {
  max-height: 70vh;
  overflow-y: auto;
}

.comment-section::-webkit-scrollbar {
  width: 4px;
}

/* Track */
.comment-section ::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.comment-section::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
.comment-section ::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.sticky-sidebar {
  height: calc(100vh - 170px);
  overflow-y: auto;
}

.hub-logo {
  max-width: 40px;
}

.addicon {
  max-width: 35px;
  margin-left: 10px;
}

.addtext {
  color: #0040a1;
  cursor: pointer;
  text-transform: uppercase;
}

.flag {
  max-width: 30px;
}

.round-image {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  margin-right: 10px;
}

.user_data_admin {
  background-color: #fff;
  box-shadow: 5px 5px 25px #dededecc;
}
.ImageShadow {
  box-shadow: 5px 5px 25px #dededecc;
}
.imageMarginTop {
  /* margin-top: -60px; */
  margin-top: 0px;
}
.partyBoxShadow {
  box-shadow: 5px 5px 25px #eeeeee;
}

.customeCheckbox label .MuiFormControlLabel-label {
  color: #50504f;
}
.customeCheckbox label span span .MuiSvgIcon-root {
  fill: #06ab04;
}
.InviteOnlyCheckbox .MuiFormControlLabel-labelPlacementStart {
  margin-left: 0 !important;
}

/* otp */
.otpSection input {
  width: 2rem !important;
  height: 2rem !important;
  border-radius: 4px;
  margin: 0 0.6rem;
  background-color: #ccc;
}
.otpSection input:focus {
  outline: none;
}
/* otp */

.RotateArrowSign {
  transform: rotate(90deg);
}

/* Editor Start */
.editorSectionStart {
  margin-top: 20px;
  border: 1px solid #bec0c7;
  min-height: 20rem;
}
.codex-editor__redactor {
  padding-bottom: 0px !important;
}
.editorClassName {
  margin-left: 2rem;
  margin-right: 2rem;
}
/* Editor Start */

/* changePassword */
.password_inputField_div {
  width: 100%;
}
.password_inputField_div input {
  margin-bottom: 2rem !important;
}
.password_inputField_div p {
  margin-top: -1rem;
  margin-bottom: 1rem;
}
/* changePassword */

.table_column_min_width {
  min-width: 15rem;
}
.cross_icon_margin {
  margin-right: 4rem !important;
}

.bulkUploadOverFlow {
  width: 100%;
  white-space: nowrap;
  overflow-x: scroll;
  -webkit-overflow-scrolling: touch;
}
.bulkUploadOverFlow::-webkit-scrollbar {
  /* -webkit-appearance: none; */
  -webkit-overflow-scrolling: auto;
}
.MuiButton-root {
  width: auto;
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}
.ForumDetailsHead {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(0, -50%);
}
.ForumDetailsHead h2 {
  font-size: 2rem;
  color: #0040a1;
  text-transform: capitalize;
}

/* Text Editor Start */
.rdw-emoji-wrapper {
  display: none !important;
}
.rdw-image-wrapper {
  display: none !important;
}
.rdw-text-align-wrapper {
  display: none !important;
}
.rdw-link-modal {
 
  height: 235px !important;
}
/* .rdw-link-wrapper {
  display: none !important;
} */
.rdw-embedded-wrapper {
  display: none !important;
}
.rdw-remove-wrapper {
  display: none !important;
}
/* .rdw-option-wrapper{
  display:none !important; ;
} */
.rdw-history-wrapper {
  display: none !important;
}
.rdw-option-active {
  display: none !important;
}
.rdw-option-wrapper:nth-child(4) {
  display: none !important;
}
.rdw-option-wrapper:nth-child(5) {
  display: none !important;
}
.rdw-option-wrapper:nth-child(6) {
  display: none !important;
}
.rdw-option-wrapper:nth-child(7) {
  display: none !important;
}
.rdw-editor-main {
  height: 270px !important;
  overflow: auto;
  box-sizing: border-box;
}
.public-DraftStyleDefault-block {
  margin: 0em !important;
}
.rdw-fontsize-wrapper {
  display: none !important;
}
.rdw-option-disabled {
  display: none !important;
}
/* Text Editor End */

.commentSectionInput {
  width: 100%;
  min-height: 2.5rem !important;
  border: 1px solid #f6f6f6 !important;
  background-color: #bec0c7;
  border-radius: 1rem;
  padding: 0 1rem;
}
.commentSectionInput:focus {
  border: none !important;
  outline: none !important;
}

.chatcontainer {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1rem;
  align-items: center;
  /* background-color: #131324; */
  background-color: #fff;
}

.innearcontainer {
  height: 85vh;
  width: 85vw;
  background-color: #eee;
  display: grid;
  grid-template-columns: 25% 75%;
  @media screen and (min-width: 720px) and (max-width: 1080px) {
    grid-template-columns: 35% 65%;
  }
}

.maincontacts {
  display: grid;
  grid-template-rows: 10% 75% 15%;
  overflow: hidden;
  background-color: #0040a1;
}

.brand {
  display: flex;
  align-items: center;
  gap: 1rem;
  justify-content: center;
}

.brand h2 {
  color: #6c91c9;
  text-transform: uppercase;
}

.contacts {
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: auto;
  gap: 0.8rem;
}
.contacts::-webkit-scrollbar {
  width: 0.5rem;
}
.contacts::-webkit-scrollbar-thumb {
  background-color: #dfdcdf;
  width: 0.5rem;
}
.contact {
  background-color: #fff;
  min-height: 3rem;
  width: 90%;
  cursor: pointer;
  /* display: flex; */
  /* align-items: center; */
  /* justify-content: center; */
}
.username {
  display: flex;
  justify-content: space-between;
  height: 48px;
  align-items: center;
  padding: 0px 20px;
}
.length{
  margin-left: 30px;
  background-color: white;
  width: 75%;
  color: #0040a1;
  border-radius: 50%;
  text-align: center;
}

.current-user {
  background-color: #0040a1;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  border-radius: 0.2rem;
  padding: 0.4rem;
  gap: 1rem;
  align-items: center;
  display: flex;
  transition: 0.5s ease-in-out;
}
.username h3 {
  color: #6c91c9;
}
.selected {
  background-color: #0040a1;
  color: #ffffff;
  border: 1px solid #fff;
}
.current-user {
}
.Welcome {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #0040a1;
  flex-direction: column;
}
.Welcome span {
  color: #0040a1;
}

.ChatContainer {
  display: grid;
  grid-template-rows: 10% 80% 10%;
  gap: 0.1rem;
  overflow: hidden;
  background: cornflowerblue;
}
.chat-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 2rem;
  background-color: #0040a1;
  color: #fff;
}
.user-details {
  display: flex;
  align-items: center;
  gap: 1rem;
}
.username h3 {
  /* color: white; */
}
.chatinput {
  display: flex;
  align-items: center;
  grid-template-columns: 5% 95%;
  background-color: #0040a1;
  padding: 0 2rem;
}
.muteunmute img{
  width: 25px;
  height: 25px;
  object-fit: contain;
}

.chatinput .button-container {
  display: flex;
  align-items: center;
  color: white;
  gap: 1rem;
}

.chatinput .button-container .emoji {
  position: relative;
  color: #25f32d;
}
svg {
  font-size: 1.5rem;
  /* color: #ffff00c8; */
  cursor: pointer;
}
.emoji-picker-react {
  position: absolute !important;
  top: -350px;
  background-color: #080420;
  box-shadow: 0 5px 10px #9a86f3;
  border-color: #9a86f3;
}
.emoji-picker-react::-webkit-scrollbar {
  background-color: #080420;
  width: 5px;
}
.emoji-picker-react::-webkit-scrollbar-thumb {
  background-color: #9a86f3;
}
.emoji-categories button {
  filter: contrast(0);
}
.emoji-search {
  background-color: transparent;
  border-color: #9a86f3;
}
.emoji-group:before {
  background-color: #080420;
}
.input-container {
  width: 100%;
  border-radius: 2rem;
  display: flex;
  align-items: center;
  gap: 2rem;
  background-color: #ffffff34;
  margin-left: 20px;
  margin-right:20px;
}
.input-container input {
  width: 90%;
  height: 60%;
  background-color: transparent;
  color: white;
  border: none;
  padding-left: 1rem;
  font-size: 1.2rem;
}
.input-container input::selection {
  background-color: #9a86f3;
}
.input-container input:focus {
  outline: none;
}
.input-container button {
  padding: 0.3rem 2rem;
  border-radius: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  border: none;
}
.button-container .muteunmute{
  padding: 0.3rem 0.7rem;
  border-radius: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  border: none;
}
.MainMesage {
  height: 80%;
}

.chat-messages {
  padding: 1rem 2rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  overflow: auto;
  background: #fff;
  color: black;
}
.chat-messages::-webkit-scrollbar {
  width: 0.2rem;
}
.chat-messages::-webkit-scrollbar-thumb {
  background-color: #ffffff39;
  width: 0.1rem;
  border-radius: 1rem;
}
.message {
  display: flex;
  align-items: center;
}
.content {
  max-width: 40%;
  overflow-wrap: break-word;
  padding: 0.3rem 0.9rem;
  font-size: 1.1rem;
  border-radius: 0.5rem;
  color: #4c4b4c;
}
.usecontent {
  max-width: 40%;
  overflow-wrap: break-word;
  padding: 0.3rem 0.9rem;
  font-size: 1.1rem;
  border-radius: 0.5rem;
  color: #4c4b4c;
  background-color: #e6e5e6;
}
.time{
  font-size: 12px;
  color: #4c4b4c;
}
.adminimage{
  width: 25px;
  height: 25px;
  object-fit: cover;
}
.adminimage img{
  border-radius: 50%;
}
.adminreply {
  display: flex;
  align-items: center;
}
.adminreply .time{
  margin-right: 10px;
}
.userreply {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
}
.userreply .time{
  margin-left: 10px;
}
.sended {
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  align-items: flex-end;
}
.content {
  background-color: #e6e5e6;
  color: #fff;
}
.recieved {
  display: flex;
  
    flex-direction: column;
    align-items: flex-start;
  justify-content: flex-start;
}
.content {
  background-color: #0040a1;
}
.usernames {
  display: flex;
  align-items: center;
  flex-direction: row-reverse;

}
.usernames img{
  width: 50px;
  object-fit: cover;
}
.usernames h3{
 margin-left: 10px;
}
aside.emoji-picker-react {
  position: absolute !important;
}
.services {
  display: flex;
  justify-content: space-between;
}
.first-division {
  display: flex;
  flex-direction: column;
  margin: 0 5px 0.5rem 0;
  width: 100%;
}
button {
  background: none;
  outline: none;
  cursor: pointer;
  font-weight: 500;
  border-radius: 2px;
  padding: 5px 10px;
}
.first-division button {
  align-self: flex-start;
  display: flex;
  align-items: center;
  margin-top: 0.5rem;
  color: rgb(0, 208, 255);
  border: 1px solid rgb(0, 208, 255);
}

.second-division button {
  color: red;
  border: 1px solid red;
}
.Questionbox {
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.questionsection {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.Questionbox li {
  list-style: none;
  margin-left: 1rem;
}
.option {
  display: flex;
  justify-content: flex-start;
}
.options {
  display: flex;
  justify-content: flex-start;
}
.options li {
  margin-left: 0px;
}

.Questionbox .toc-icon2 {
  width: 290px;
  height: 290px;
  object-fit: contain;
}
.actionsection {
  width: 12%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.dot{
  width: 60px;
}
.left{
  margin-left: auto;
}
.answersection {
  width: 20%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.MuiFormGroup-root {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row !important;
}
.testMessageShowAudio{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  color: #fff;
  padding-left: 22px;
}
.crossbtn{
  color: #fff;
  cursor: pointer;
  margin-left: 30px;
}
/* .chekboxgroup {
  display: flex !important;
  flex-direction: row !important;
  flex-wrap: wrap !important;
} */
/* .customcheckboxs{
  display: inline-flex !important;
  justify-content: center !important;
} */
.st-menu {
	position: absolute;
	top: 0;
	left: 0;
	z-index: 100;
	visibility: hidden;
	width: 300px;
	height: 100%;
	background: #48a770;
	-webkit-transition: all 0.5s;
	transition: all 0.5s;
}

.sidebar {
  position: fixed;
  top: 10%;
  right: -1000px;
  width: 1000px;
  height: 94vh;
  background-color: #fff;
  transition: left 0.3s ease-in-out;
  z-index: 999;
  display: flex;
    flex-direction: column;

}

.sidebar.open {
  right: 0;
}

.close-button {
  /* margin-bottom: 1rem; */
  display: flex;
  justify-content: flex-end;
  font-size: 24px;
    font-weight: 500;
    margin-right: 35px;
    margin-top: 20px;
}
.listsession{
  display: flex;
  padding: 15px 15px;
  width: 100%;
}
.Sessionbody {
  margin: 25px 0px;
  overflow: auto;
  width: 100%;
}
.questionID{
  margin-right: 20px;
}
.listcontent {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.buttonsection button{
  margin-left: 10px;
}
/* .SubSession{
  width: 10%;
} */
.SubSessionhead{
 display: flex;
 justify-content: flex-start;
 align-items: center;
 /* width: 100%; */
 margin-left: 18px;
}
.Zoombtn{
  display: flex;
  padding: 10px 0px;
}
.zoombutton{
  background-color: #0040a1;
  width: 50px;
  height: 50px;
  margin: 5px;
  color: #fff;
  font-size: 24px;
}
.coursehead{
  display: flex;
  justify-content: center;
  font-size: 16px;
  font-weight: bold;
  width: 100%;
}
.Sessionbody .MuiAccordionSummary-root{
  padding: 0px !important;
  overflow-x: hidden;
}
.coursehead h2{
  margin: auto;
}
.viewtranslate {
  display: flex;
  justify-content: flex-end;
}
.subsessiontitle {
  width: 40%;
    display: flex;
    justify-content: flex-start;
}
.samebutton {
  width: 150px;
 
}
.subsessiontype {
  width: 30%;
  display: flex;
  justify-content: center;
}
.buttonsection {
  width: 30%;
  display: flex;
  justify-content: flex-end;
}
.pdfbox {
  display: flex;
}
.cropperclass{
  display: flex !important;
  flex-direction: column;
  justify-content: center;
  position: relative;
  
}
.MuiTabs-flexContainer{
  overflow-x: auto !important;
  cursor: pointer;
}
.MuiTabs-flexContainer::-webkit-scrollbar {
  height: 6px;
 
}


.MuiTabs-flexContainer::-webkit-scrollbar-thumb {
  background: #0040a1;
  border-radius: 4px;
}

.MuiTabs-flexContainer::-webkit-scrollbar-thumb:hover {
  background: #c5d5ef;
}
.scroll-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 30px;
  height: 30px;
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.scroll-button.left {
  left: 0.5rem;
}

.scroll-button.right {
  right: 0.5rem;
}


.activecardhead {
  font: normal normal 600 18px/33px "Noto Sans";
  color: #111111;
  line-height: 25px;
  text-align: justify;
}  


.post-detail .header,
.post-polling .header {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  position: absolute;
  top: -8%;
  background-color: transparent;
  box-shadow: none;
  padding: 0 0.6rem;
  color: #0040a1;
  font-size: 0.9rem;
  height: auto;
  z-index: 2;
}

.post-detail .header img,
.post-polling .header img {
  width: 50px;
  height: 50px;
  border-radius: 100%;
  margin-right: 10px;
  border: 2px solid #fff;
}

.post-detail .header img.home,
.post-polling .header img.home {
  width: 55px;
  height: 55px;
}

.post-detail.without-text .footer {
  padding-top: 50px;
}

.post-detail .footer .title {
  padding-top: 0.5rem;
  color: #0040a1;
}

.post-detail .footer .text {
  font-size: 0.8rem;
  color: #50504f;
}

.post-polling.modal-view,
.post-detail.modal-view {
  display: flex;
  justify-content: space-between;
}

.post-polling.modal-view .content,
.post-detail.modal-view .content {
  width: 50%;
}

.post-polling.modal-view .footer,
.post-detail.modal-view .footer {
  width: 45%;
}

.post-polling.modal-view .footer h3,
.post-detail.modal-view .footer h3 {
  margin-bottom: 10px !important;
  border: 0 !important;
}

.polling {
  font-size: 0.8rem;
  color: #50504f;
  padding: 0.5rem;
  background-color: #fff;
  margin-top: 1rem;
  cursor: pointer;
  transition: 0.5s all ease;
  position: relative;
}

.polling span {
  position: relative;
  z-index: 9;
  color: #0040a1;
  font-weight: 600;
}

.polling .progress-bar {
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #dee9f9;
}

.polling .progress-text {
  position: absolute;
  top: 0;
  right: 10px;
  height: 100%;
  display: flex;
  align-items: center;
  color: #0040a1;
  font-weight: 600;
}





.polling {
  font-size: 0.8rem;
  color: #50504f;
  padding: 0.5rem;
  background-color: #fff;
  margin-top: 1rem;
  cursor: pointer;
  transition: 0.5s all ease;
  position: relative;
}

.polling span {
  position: relative;
  z-index: 9;
  color: #184183;
  font-weight: 600;
}

.polling .progress-bar {
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #dee9f9;
}

.polling .progress-text {
  position: absolute;
  top: 0;
  right: 10px;
  height: 100%;
  display: flex;
  align-items: center;
  color: #184183;
  font-weight: 600;
}


.polling .progress-bar {
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #dee9f9;
}


.polling .progress-text {
  position: absolute;
  top: 0;
  right: 10px;
  height: 100%;
  display: flex;
  align-items: center;
  color: #0040a1;
  font-weight: 600;
}
.showLeft {
  background-color: white !important;
  border: 1px solid white !important;
  text-shadow: white !important;
  color: black !important;
  padding: 10px;
}
.editimage{
  display: flex;
  /* margin-left: 41px; */
    margin-top: 40px;
    /* justify-content: space-between; */
    width: 100%;
    /* margin-left: 41px; */
    justify-content: flex-start;
}
.icons li {
  background: none repeat scroll 0 0 black;
  height: 7px;
  width: 7px;
  line-height: 0;
  list-style: none outside none;
  margin-right: 15px;
  margin-top: 3px;
  vertical-align: top;
  border-radius: 50%;
  pointer-events: none;
}
.btn-left {
  left: 0.4em;
}
.btn-right {
  right: 0.4em;
}
.btn-left,
.btn-right {
  position: absolute;
  top: 0.24em;
}
.dropbtn {
  background-color: #4caf50;
  position: fixed;
  color: white;
  font-size: 16px;
  border: none;
  cursor: pointer;
}
.dropbtn:hover,
.dropbtn:focus {
  background-color: #3e8e41;
}
.dropdown {
  position: absolute;
  display: inline-block;
  right: 0.4em;
}
.dropdown-content {
  display: none;
  position: relative;
  margin-top: 60px;
  background-color: #f9f9f9;
  min-width: 160px;
  overflow: auto;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-content p {
cursor: pointer;
}

.dropdown a:hover {
  background-color: #f1f1f1;
}

.show {
  display: block;
}
.test {
  position: relative;
}
.tests {
  position: relative;
}
button.threedot {
  position: absolute;
  right: -33px;
  top: -4px;
  transform: rotate(90deg);
}
button.threedotaudio {
  position: absolute;
  right: -31px;
  top: 9px;
  transform: rotate(90deg);
}
.dropdown-contents {
  background: red;
  width: 58px;
  height: 31px;
  position: absolute;
  right: -14px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 13%;
  font-size: 14px;
  cursor: pointer;
  /* color: red; */
}
@media (min-width: 600px) {
  .MuiTab-root {
    min-width: 145px !important;
  }
}

@media (min-width: 1560px) {
  .MuiTabs-flexContainer {
    display: flex;
    width: 100%;
}
}
@media (min-width: 1360px) {
  .MuiTabs-flexContainer {
    display: flex;
    width: 100%;
}
}
@media (min-width: 1140px) {
  .MuiTabs-flexContainer {
    display: flex;
    width: 85%;
    cursor: pointer;
}
}
@media (max-width: 991px) {
  .logincontainer{
    width: 100% !important;
  }
  .inner-header {
    width: 100% !important;
  }

  .Questionbox {
 
    width: 100%;
  }
  .actionsection {
    width: 26%;
   
}
.homemainresponsive{
  padding: 0.5rem 0.5rem !important;
}

.header {
  width: 100%;
  display: flex;
  padding: 20px 0;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  box-shadow: 5px 5px 25px #dededecc;
  
  height: auto;
}
/* .page-container {
  width: 100% ;
  height: 100vh;
  background-color: #f6f6f6;
} */

.headres{
  padding: 0 0px!important;
}

.addtext {
   
    position: fixed;
    top: 125px;
}

.innearcontainer {
  z-index: 999999;
}

}
.progress{
  position: "absolute";
  top: "50%";
  left: "50%"
}
@media (max-width: 567px) {
  .responsiveclass{
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-around;

  }
  .cutome_calander_class abbr {
    position: relative;
    padding: 10px 11px;
}
  .addtext {
   
    position: fixed;
    top: 135px;
}
  #custom-modal {
   
    padding: 2rem 3rem 2rem;
  
}
#model {
  padding: 2rem 1rem 2rem;
  margin: 0 auto;
    width: 100%;
    border: 0;
    outline: none;
    max-height: 90vh;
    overflow-y: scroll;
}
  
  .responsivetab{
    margin-top: 50px;
  }
  /* .header {
   
    flex-wrap: wrap;
  
  } */
  .header .search-wrapper {
    display: flex;
    justify-content: flex-start;
 
    
  }
  /* .inner-header {
    
    top: 114px;
} */
  /* .header .search-wrappers {
    display: flex;
    justify-content: flex-end;
    

  } */
}
@media (max-width: 487px) {
  #custom-modal {
   
    padding: 2rem 1rem 2rem;
  
}
#custom-modal .cross {

  right: 0rem;
}

  .activecardhead {
    font: normal normal 600 14px/33px "Noto Sans";
  }
}

.search-input {
  background-color: #f0f0f0; /* Light gray background */
  color: #333; /* Dark text color */
  transition: border-color 0.3s ease; /* Smooth transition for border color */

  /* Adjust padding and border radius for better aesthetics */
  padding: 0.5rem;
  border-radius: 0.5rem;

  /* Set width to 90% */
  width: 90%;
}

.search-input:focus {
  /* Highlight border color on focus */
  border-color: #3490dc; /* Example: Light blue */
}
.minutes{
  display: flex;
  align-items: center;
}
.storydownload {
  display: flex;
  align-items: center;
  margin-left:80px;
}
.storydownload a{
 margin-left: 20px;
 color: blue;
}
.storydownload a:hover{
  color: blue;
  text-decoration: underline;
 
}
.storydownloads {
  display: flex;
  align-items: center;
  /* margin-left:80px; */
}
.storydownloads a{
 margin-left: 20px;
 color: blue;
}
.storydownloads a:hover{
  color: blue;
  text-decoration: underline;
 
}