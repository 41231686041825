.icon {
    max-width: 0.8rem;
}

.num {
    font-size: 0.7rem;
    color: #0040a1;
    font-family: 'ArialReg';
    margin-right: 5px;
}

.profileImage {
    max-width: 30px;
    height: auto;
}

.name {
    display: inline;
    font-family: inherit;
    font-weight: 700;
    color: #0040a1;
    margin-right: 5px;
}

.comment {
    font-size: 1rem;
    color: #4D4D4F;
}